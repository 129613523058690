import { exists, window } from "browser-monads"
const dataLayerPush = data => {
  if (exists(window) && window.dataLayer) {
    window.dataLayer.push(data)
  }
}
const dataLayerPushPageInfo = details => {
  return dataLayerPush({ event: "10a.page.info", ...details })
}
export const GTM = {
  dataLayerPush,
  dataLayerPushPageInfo,
}
export default GTM
