import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js"
import { window, exists } from "browser-monads"
import GTM from "@src/services/gtm"

const userPool = new CognitoUserPool({
  UserPoolId: process.env.GATSBY_AWS_USER_POOL_ID,
  ClientId: process.env.GATSBY_AWS_USER_POOL_WEB_CLIENT_ID,
})
let activeUser

const isBrowser = () => exists(window)

// Source: https://www.npmjs.com/package/amazon-cognito-identity-js
// Use case 16. Retrieving the current user from local storage.
// (note there are two Use case 16, skip the React Native one)
const getUser = (baseObject = false) =>
  new Promise((resolve, reject) => {
    if (!isBrowser()) {
      reject("User is not available outside of browser.")
      return
    }
    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser === null) {
      GTM.dataLayerPush({
        event: "10a.user.authentication.check",
        "user-authenticated": false,
      })
      resolve(undefined)
      return
    }
    cognitoUser.getSession(function (err) {
      if (err) {
        reject(err.message || JSON.stringify(err))
        return
      }
      activeUser = cognitoUser

      if (baseObject) {
        resolve(activeUser)
        return
      }

      GTM.dataLayerPush({
        event: "10a.user.authentication.check",
        "user-authenticated": true,
      })

      // Use case 5. Retrieve user attributes for an authenticated user.
      cognitoUser.getUserAttributes(function (err, attributes) {
        if (err) {
          reject(err.message || JSON.stringify(err))
          return
        }
        resolve(attributes)
      })
    })
  })
const getUserToken = () => {
  return getUser(true).then(user => {
    if (user && user.signInUserSession?.idToken?.jwtToken) {
      return user.signInUserSession.idToken.jwtToken
    }
    throw new Error("User token could not be found.")
  })
}
// Source: https://www.npmjs.com/package/amazon-cognito-identity-js
// Use case 8. Update user attributes for an authenticated user.
const updateAttributes = updates =>
  new Promise((resolve, reject) => {
    getUser(true).then(user => {
      if (!user) {
        reject("User is not signed in")
        return
      }
      const attributeList = []
      for (const key in updates) {
        const attribute = new CognitoUserAttribute({
          Name: key,
          Value: updates[key],
        })
        attributeList.push(attribute)
      }
      if (attributeList.length) {
        user.updateAttributes(attributeList, function (err) {
          if (err) {
            reject(err.message || JSON.stringify(err))
            return
          }
          resolve(true)
        })
      }
    })
  })

// Source: https://www.npmjs.com/package/amazon-cognito-identity-js
// Use case 4. Authenticating a user and establishing a user session with the Amazon Cognito Identity service.
const signin = (username, password) =>
  new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: username,
    })
    cognitoUser.authenticateUser(
      new AuthenticationDetails({
        Username: username,
        Password: password,
      }),
      {
        onSuccess: function () {
          activeUser = cognitoUser
          GTM.dataLayerPush({
            event: "10a.user.signin.success",
            "user-authenticated": true,
          })
          resolve(true)
        },
        onFailure: function (err) {
          GTM.dataLayerPush({
            event: "10a.user.signin.failed",
            "user-authenticated": false,
          })
          reject(err.message || JSON.stringify(err))
        },
      }
    )
  })

// Source: https://www.npmjs.com/package/amazon-cognito-identity-js
// Use case 15. Global signout for an authenticated user(invalidates all issued tokens).
const signout = () =>
  new Promise((resolve, reject) => {
    if (!activeUser) {
      resolve()
      return
    }
    activeUser.globalSignOut({
      onSuccess: function () {
        activeUser = undefined
        GTM.dataLayerPush({
          event: "10a.user.signout.success",
          "user-authenticated": false,
        })
        resolve(true)
      },
      onFailure: function (err) {
        GTM.dataLayerPush({
          event: "10a.user.signout.failed",
        })
        reject(err.message || JSON.stringify(err))
      },
    })
  })

// Source: https://www.npmjs.com/package/amazon-cognito-identity-js
// Use case 1. Registering a user with the application.
const signup = (username, password) =>
  new Promise((resolve, reject) => {
    userPool.signUp(
      username,
      password,
      [
        new CognitoUserAttribute({
          Name: "email",
          Value: username,
        }),
      ],
      null,
      (err, result) => {
        if (err) {
          GTM.dataLayerPush({
            event: "10a.user.signup.failed",
          })
          reject(err.message || JSON.stringify(err))
          return
        } else if (!result || !result.user) {
          GTM.dataLayerPush({
            event: "10a.user.signup.failed",
          })
          reject("Could not register user.")
          return
        }
        GTM.dataLayerPush({
          event: "10a.user.signup.success",
        })
        resolve(true)
      }
    )
  })

// Source: https://www.npmjs.com/package/amazon-cognito-identity-js
// Use case 2. Confirming a registered, unauthenticated user using a confirmation code received via SMS.
const verifyAccount = (username, code) =>
  new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: username,
    })
    cognitoUser.confirmRegistration(code, true, function (err) {
      if (err) {
        GTM.dataLayerPush({
          event: "10a.user.verification.failed",
        })
        reject(err.message || JSON.stringify(err))
        return
      }
      GTM.dataLayerPush({
        event: "10a.user.verification.success",
      })
      resolve(true)
    })
  })

// Source: https://www.npmjs.com/package/amazon-cognito-identity-js
// Use case 3. Resending a confirmation code via SMS for confirming registration for a unauthenticated user.
const verificationResend = username =>
  new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: username,
    })
    cognitoUser.resendConfirmationCode(function (err) {
      if (err) {
        GTM.dataLayerPush({
          event: "10a.user.verification-resend.failed",
        })
        reject(err.message || JSON.stringify(err))
        return
      }
      GTM.dataLayerPush({
        event: "10a.user.verification-resend.success",
      })
      resolve(true)
    })
  })

// Source: https://www.npmjs.com/package/amazon-cognito-identity-js
// Use case 12. Starting and completing a forgot password flow for an unauthenticated user.
const recoverAccount = username =>
  new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: username,
    })
    cognitoUser.forgotPassword({
      onSuccess: function () {
        GTM.dataLayerPush({
          event: "10a.user.account-recovery.success",
        })
        resolve(true)
      },
      onFailure: function (err) {
        GTM.dataLayerPush({
          event: "10a.user.account-recovery.failed",
        })
        reject(err.message || JSON.stringify(err))
      },
    })
  })
// Source: https://www.npmjs.com/package/amazon-cognito-identity-js
// Use case 12. Starting and completing a forgot password flow for an unauthenticated user.
const recoverPassword = (username, code, newPassword) =>
  new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Pool: userPool,
      Username: username,
    })
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess() {
        GTM.dataLayerPush({
          event: "10a.user.password-reset.success",
        })
        resolve(true)
      },
      onFailure(err) {
        GTM.dataLayerPush({
          event: "10a.user.password-reset.failed",
        })
        reject(err.message || JSON.stringify(err))
      },
    })
  })

const AuthService = {
  getUser,
  getUserToken,
  signin,
  signout,
  signup,
  recoverAccount,
  recoverPassword,
  verifyAccount,
  verificationResend,
  updateAttributes,
}
export default AuthService
