import { window, document, CustomEvent, exists } from "browser-monads"
import GTM from "@src/services/gtm"
// import { showCookieScript } from "@src/utils/cookieScript.js"

function ckyHandleConsentChoice(e, choice) {
  if (!exists(window)) {
    return
  }
  setTimeout(function () {
    const eventData = {
      "cky-choice":
        choice && choice.accept
          ? "accept"
          : choice && choice.reject
            ? "reject"
            : undefined,
    }
    if (exists(CustomEvent)) {
      window.dispatchEvent(
        new CustomEvent("cky.consent.choice", { detail: eventData }),
      )
    }
    GTM.dataLayerPush({
      event: "cky.consent.choice",
      ...eventData,
    })
  }, 500)
  setTimeout(function () {
    if (exists(CustomEvent)) {
      window.dispatchEvent(new CustomEvent("cky.consent.updated"))
    }
    GTM.dataLayerPush({ event: "cky.consent.updated" })
  }, 800)
}
function ckyHandleAcceptClick(e) {
  ckyHandleConsentChoice(e, { accept: true })
}
function ckyHandleRejectClick(e) {
  ckyHandleConsentChoice(e, { reject: true })
}
function ckyRegisterListeners() {
  if (!exists(document)) {
    return
  }
  document.querySelector("body").addEventListener("click", function (e) {
    if (e && e.target && e.target.classList) {
      if (e.target.classList.contains("cky-btn-accept")) {
        ckyHandleAcceptClick(e)
      } else if (e.target.classList.contains("cky-btn-reject")) {
        ckyHandleRejectClick(e)
      }
    }
  })
}
function ckyScriptLoaded() {
  // showCookieScript()
  ckyRegisterListeners()
}

export const onRouteUpdate = (api, { id, delayLoad }) => {
  if (
    typeof window === "undefined" ||
    typeof window.loadScript !== "function"
  ) {
    return
  }

  const existingScript = document.querySelector(
    `script[src="https://cdn-cookieyes.com/client_data/${id}/script.js"]`,
  )

  if (existingScript) {
    console.log("CookieYes script is already loaded")
    return
  }

  setTimeout(() => {
    window.loadScript({
      id: "cookieyes",
      src: `https://cdn-cookieyes.com/client_data/${id}/script.js`,
      type: "text/javascript",
      delay: delayLoad || null,
      defer: true,
      idle: true,
      onload: ckyScriptLoaded,
    })
  }, 4500)
}
