import { window, exists } from "browser-monads"
import GTM from "@src/services/gtm"
import { LOCALSTORAGE_KEY_LOCALE } from "../_config"

const isBrowser = () => exists(window)

const getLocale = generateDataLayer => {
  if (!isBrowser()) {
    return false
  }
  const localeRaw = window.localStorage.getItem(LOCALSTORAGE_KEY_LOCALE)
  const locale = localeRaw ? JSON.parse(localeRaw) : null
  if (locale && generateDataLayer) {
    const dataLayer = {
      event: "10a.user.locale.loaded",
      "user-locale-country": "",
      "user-locale-currency": "",
      "user-locale-units": "",
    }
    if (locale.country) {
      dataLayer["user-locale-country"] = locale.country
    }
    if (locale.currency && locale.currency.code) {
      dataLayer["user-locale-currency"] = locale.currency.code
    }
    if (locale.units) {
      dataLayer["user-locale-units"] = locale.units
    }
    GTM.dataLayerPush(dataLayer)
  }
  return locale
}
const setLocale = locale => {
  const dataLayer = {
    event: `10a.user.locale.${locale ? "updated" : "reset"}`,
    "user-locale-country": "",
    "user-locale-currency": "",
    "user-locale-units": "",
  }
  if (locale) {
    if (locale.country) {
      dataLayer["user-locale-country"] = locale.country
    }
    if (locale.currency && locale.currency.code) {
      dataLayer["user-locale-currency"] = locale.currency.code
    }
    if (locale.units) {
      dataLayer["user-locale-units"] = locale.units
    }
  }
  GTM.dataLayerPush(dataLayer)
  window.localStorage.setItem(LOCALSTORAGE_KEY_LOCALE, JSON.stringify(locale))
}
const updateLocale = changes => {
  const currentLocale = getLocale() || {}
  setLocale({
    ...currentLocale,
    ...changes,
  })
}
const LocaleService = {
  getLocale,
  setLocale,
  updateLocale,
}
export default LocaleService
