import { useEffect } from "react"
import { exists, window } from "browser-monads"

export const useGlobalDomEvents = events => {
  useEffect(() => {
    if (
      !exists(window) ||
      !events ||
      typeof events !== "object" ||
      Array.isArray(events)
    ) {
      return
    }
    for (const [event, callback] of Object.entries(events)) {
      window.addEventListener(event, callback)
    }
    return () => {
      for (const [event, callback] of Object.entries(events)) {
        window.removeEventListener(event, callback)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
