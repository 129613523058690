export const clientQueryGetUserLocale = () => {
  return `query GetUserLocale($currency: String)
    {
      visitor(currency_code: $currency) {
        locale {
          country
          currency {
            code
            exchangeRates {
              toCode: to
              fromCode: from
              rate
            }
          }
          units
        }
      }
  }`
}
