import { window, exists } from "browser-monads"
import GTM from "@src/services/gtm"
import { LOCALSTORAGE_KEY_PREFS } from "./_config"

const isBrowser = () => exists(window)

const getPrefs = generateDataLayer => {
  if (!isBrowser()) {
    return false
  }
  const prefsRaw = window.localStorage.getItem(LOCALSTORAGE_KEY_PREFS)
  const prefs = prefsRaw ? JSON.parse(prefsRaw) : null
  if (generateDataLayer) {
    GTM.dataLayerPush({
      event: "10a.user.preferences.loaded",
    })
  }
  return prefs
}
const setPrefs = prefs => {
  GTM.dataLayerPush({
    event: "10a.user.preferences.updated",
  })
  window.localStorage.setItem(LOCALSTORAGE_KEY_PREFS, JSON.stringify(prefs))
}
const updatePrefs = changes => {
  if (!changes || !Object.keys(changes).length) {
    return
  }
  const currentPrefs = getPrefs() || {}
  setPrefs({
    ...currentPrefs,
    ...changes,
  })
}
const PrefsService = {
  getPrefs,
  setPrefs,
  updatePrefs,
}
export default PrefsService
