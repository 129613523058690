module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M9C8724","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../plugins/10a-cookieyes/gatsby-browser.js'),
      options: {"plugins":[],"id":"7712f0fd34b8c5a9d828ca2b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://api.10adventures.com/?graphql","schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":5,"timeout":180000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"html":{"createStaticFiles":false,"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"type":{"__all":{"limit":null},"Comment":{"exclude":true},"MediaItem":{"createFileNodes":false,"localFile":{"requestConcurrency":5,"maxFileSizeBytes":1,"excludeByMimeTypes":[]},"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
