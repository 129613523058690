import { window, FormData, CustomEvent, exists } from "browser-monads"
import axios from "axios"
import GTM from "@src/services/gtm"
import {
  LOCALSTORAGE_KEY_REFERRAL,
  GOAFFPRO_URL,
  GOAFFPRO_ACCESS_PUBLIC_KEY,
  GOAFFPRO_ACCESS_TOKEN,
} from "./_config"

// Go Aff Pro - Order Creation
const getGoAffProHeaders = publicKey => {
  const headers = { accept: "application/json" }
  if (publicKey) {
    headers["X-GOAFFPRO-PUBLIC-TOKEN"] = GOAFFPRO_ACCESS_PUBLIC_KEY
  } else {
    headers["X-GOAFFPRO-ACCESS-TOKEN"] = GOAFFPRO_ACCESS_TOKEN
  }
  return { headers }
}

// https://api.goaffpro.com/docs/admin/#/orders/post_admin_orders
export const createAffiliateOrder = orderData => {
  return axios.post(
    `${GOAFFPRO_URL}/admin/orders/`,
    { ...orderData },
    { ...getGoAffProHeaders() }
  )
}

// Referral Code Tracking - Some Legacy Code With WP Integrations
const envIsReady = () => exists(window) && window.localStorage
const activateReferral = refCode => {
  if (!envIsReady()) {
    return
  }

  const referralExpiry = new Date(
    new Date().getTime() +
      1000 * 60 * 60 * 24 * process.env.GATSBY_REFERRAL_TTL_DAYS
  )
  window.localStorage.setItem(LOCALSTORAGE_KEY_REFERRAL, refCode)
  window.localStorage.setItem(
    `${LOCALSTORAGE_KEY_REFERRAL}_expiry`,
    referralExpiry.getTime()
  )
}
const getActiveReferral = () => {
  if (!envIsReady()) {
    return
  }
  return window.localStorage.getItem(LOCALSTORAGE_KEY_REFERRAL)
}
const verifyActiveReferral = () => {
  if (!envIsReady()) {
    return
  }
  const refCodeExpiry = window.localStorage.getItem(
    `${LOCALSTORAGE_KEY_REFERRAL}_expiry`
  )
  if (refCodeExpiry) {
    if (refCodeExpiry <= new Date().getTime()) {
      window.localStorage.removeItem(LOCALSTORAGE_KEY_REFERRAL)
      window.localStorage.removeItem(`${LOCALSTORAGE_KEY_REFERRAL}_expiry`)
    } else {
      return getActiveReferral()
    }
  }
}

const trackReferralVisit = (location, referrer) => {
  if (!envIsReady() || !exists(FormData)) {
    return
  }
  const refCode = getActiveReferral()
  if (!refCode) {
    return
  }
  const formData = new FormData()
  formData.append("action", "10a_referral_visit")
  formData.append("code", refCode)
  formData.append("location", location)
  if (referrer) {
    formData.append("referrer", referrer)
  }
  axios
    .post(process.env.GATSBY_REST_ENDPOINT, formData)
    .catch(error => console.log("Error in getActiveReferral request: ", error))
}
const trackReferralLead = leadData => {
  if (!envIsReady() || !exists(FormData) || !window.location) {
    return
  }
  const refCode = getActiveReferral()
  if (!refCode) {
    return
  }
  const formData = new FormData()
  formData.append("action", "10a_referral_lead")
  formData.append("refCode", refCode)
  formData.append("location", window.location.href)
  formData.append("eventType", leadData.eventType || "")
  formData.append("leadName", leadData.leadName || "")
  formData.append("leadEmail", leadData.leadEmail || "")
  axios
    .post(process.env.GATSBY_REST_ENDPOINT, formData)
    .catch(error => console.log("Error in trackReferralLead request: ", error))
}

const checkReferralCode = refCode => {
  if (!envIsReady()) {
    return
  }
  if (refCode) {
    activateReferral(refCode)
  }
  return getActiveReferral()
}

const registerEventListeners = () => {
  if (!envIsReady()) {
    return
  }
  window.addEventListener("10a.affiliate.lead", event => {
    if (!event || !event.detail) {
      return
    }
    trackReferralLead({
      eventType: event.detail.eventType,
      leadName: event.detail.leadName,
      leadEmail: event.detail.leadEmail,
    })
  })
  window.addEventListener("10a.affiliate.checkRefCode", event => {
    if (!event || !event.detail) {
      return
    }
    const { refCode } = event.detail
    const activeRefCode = checkReferralCode(refCode)
    if (activeRefCode) {
      GTM.dataLayerPush({
        event: "10a.affiliate.referral",
        referralCode: activeRefCode,
      })
    }
  })
  window.addEventListener("10a.affiliate.trackActiveRef", event => {
    if (!event || !event.detail) {
      return
    }
    if (
      typeof window.hasCookieConsent !== "function" ||
      !window.hasCookieConsent("analytics")
    ) {
      return
    }
    const { location, referrer } = event.detail
    const activeRefCode = verifyActiveReferral()
    if (activeRefCode) {
      trackReferralVisit(location, referrer)
    }
  })
  window.addEventListener("10a.affiliate.verifyActiveRef", event => {
    if (!event || !event.detail) {
      return
    }
    const activeRefCode = verifyActiveReferral()
    if (activeRefCode) {
      GTM.dataLayerPush({ referralCode: activeRefCode })
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent("10a.affiliate.trackActiveRef", {
            detail: event.detail,
          })
        )
      }, 1000)
    } else {
      GTM.dataLayerPush({ referralCode: undefined })
    }
  })
  window.addEventListener("cky.consent.updated", () => {
    if (!exists(window) || !window.location || !window.location.href) {
      return
    }
    const eventData = {
      location: window.location.href,
    }
    window.dispatchEvent(
      new CustomEvent("10a.affiliate.verifyActiveRef", { detail: eventData })
    )
  })
}

const checkReferralCodes = ({ location, prevLocation }) => {
  if (!envIsReady() || !exists(CustomEvent)) {
    return
  }
  if (location) {
    const eventData = {
      location: location.href,
      referrer: prevLocation && prevLocation.href,
    }
    if (location.search) {
      const params = new URLSearchParams(location.search)
      const refCode = params.get("ref") || params.get("refer")
      if (refCode) {
        window.dispatchEvent(
          new CustomEvent("10a.affiliate.checkRefCode", {
            detail: {
              ...eventData,
              refCode,
            },
          })
        )
      }
    }
    window.dispatchEvent(
      new CustomEvent("10a.affiliate.verifyActiveRef", {
        detail: {
          ...eventData,
        },
      })
    )
  }
}

const AffiliateReferrals = {
  initialize: () => {
    if (!envIsReady() || AffiliateReferrals.isInitialized) {
      return
    }
    AffiliateReferrals.isInitialized = true
    registerEventListeners()
  },
  checkReferralCodes: params => {
    if (!AffiliateReferrals.isInitialized) {
      AffiliateReferrals.initialize()
    }
    checkReferralCodes(params)
  },
  getActiveReferral,
}
export default AffiliateReferrals
