import { useStaticQuery, graphql } from "gatsby"

// static query for tenAdventures.currencies
// - loads currency exchange rates used for static publish & react initialization (hydration)
//    - static HTML/CSS files and react's render state at hydration must match
// - app is initialized using PUBLISH_CURRENCY so useCurrencies() needs to support it
//    - USD is hard-coded because variables are not available in static queries
//    - useCurrencies static query must include:
//        `exchangeTo${PUBLISH_CURRENCY}: exchangeRate(to: ${PUBLISH_CURRENCY})`
//
// dynamic/real-time exchange rates:
//  - after hydration, exchange rates are updated if available
//    - locale-context checks for exchange rates in localstorage
//      - applies them to UI immediately
//      - renders static exchange rates if there's none in localstorage
//    - gatsby-brower.js::onInitialClientRender dispatches 10a.locale.detect event
//    - locale-context handles 10a.locale.detect event
//      - services/user.locale.remote.detectLocale() gets the latest exchange rates
//        - services/user.pref currency is applied to result (if user is logged in)
//        - detected locale is used as default if the user logs out
//        - updated exchange rates are cached to localstorage
//      - locale-context refreshes UI with updated exchange rates
export const useCurrencies = () => {
  const {
    tenAdventures: { currencies },
  } = useStaticQuery(graphql`
    query CurrenciesQuery {
      tenAdventures {
        currencies {
          code
          symbol
          exchangeToUSD: exchangeRate(to: USD)
        }
      }
    }
  `)

  return currencies
}
