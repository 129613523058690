import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react"
import PropTypes from "prop-types"
import { detectLocale as detectUserLocale } from "@src/services/user/locale/remote"
import { useGlobalDomEvents } from "@src/hooks/useGlobalDomEvents"
import Locale from "@src/services/user/locale"
import { PrefsContext } from "@src/context/prefs-context"
import {
  CURRENCY_EXCHANGE_TAX,
  PUBLISH_CURRENCY,
} from "@src/utils/constants/currency"
import { DEFAULT_LOCALE_UNITS } from "@src/utils/constants/locale"
import { useCurrencies } from "@src/hooks/useCurrencies"

export const LocaleContext = React.createContext({
  country: undefined,
  currency: {
    code: undefined,
    exchange: {},
  },
  convertCurrency: () => {},
  units: undefined,
  updatePreferredUnits: () => {},
})

export const LocaleProvider = ({ children }) => {
  const {
    prefUnits,
    updatePrefUnits,
    prefCurrency,
    updatePrefCurrency,
  } = useContext(PrefsContext)

  const staticCurrencies = useCurrencies()
  const staticCurrencyRates = useMemo(() => {
    if (!staticCurrencies || !staticCurrencies.length) {
      return
    }
    // prepare the results of useCurrencies to use PUBLISH_CURRENCY
    // - used for static publish and hydration (or when user's currency is not detected)
    const result = {
      code: PUBLISH_CURRENCY,
    }
    result.exchange = staticCurrencies.reduce(
      (exchangeRatesResult, staticCurrencyEntry) => {
        if (staticCurrencyEntry[`exchangeTo${result.code}`]) {
          exchangeRatesResult[staticCurrencyEntry.code] =
            staticCurrencyEntry[`exchangeTo${result.code}`]
        }
        return exchangeRatesResult
      },
      {}
    )
    return result
  }, [staticCurrencies])

  const persistLocale = changes => Locale.updateLocale(changes)

  // initialize provider state
  const [country, setCountry] = useState()
  const [currency, setCurrency] = useState({ code: PUBLISH_CURRENCY })
  const [defaultCurrency, setDefaultCurrency] = useState({
    code: PUBLISH_CURRENCY,
  })
  const [currencyRates, setCurrencyRates] = useState(staticCurrencyRates)
  const [units, setUnits] = useState(prefUnits || DEFAULT_LOCALE_UNITS)
  const [defaultUnits, setDefaultUnits] = useState(DEFAULT_LOCALE_UNITS)

  useGlobalDomEvents({
    "10a.locale.detect": () => {
      detectUserLocale().then(newLocale => {
        if (newLocale.defaultCurrency) {
          setDefaultCurrency(newLocale.defaultCurrency)
        }
        if (newLocale.defaultUnits) {
          setDefaultUnits(newLocale.defaultUnits)
        }
        // update provider state
        setCountry(newLocale.country)
        setCurrencyRates(newLocale.currencyRates)
        setCurrency(newLocale.currency)
        setUnits(newLocale.units)
      })
    },
  })

  useEffect(() => {
    const initLocale = Locale.getLocale(true)
    if (initLocale) {
      if (initLocale.defaultCurrency) {
        setDefaultCurrency(initLocale.defaultCurrency)
      }
      if (initLocale.defaultUnits) {
        setDefaultUnits(initLocale.defaultUnits)
      }
      setCountry(initLocale.country)
      setCurrency(initLocale.currency)
      setCurrencyRates(initLocale.currencyRates)
      setUnits(initLocale.units)
    }
  }, [])

  const updateLocaleCurrency = useCallback(
    currency => {
      if (!currency && defaultCurrency?.code) {
        currency = defaultCurrency?.code
      }
      if (!currency && currencyRates?.code) {
        currency = currencyRates?.code
      }
      currency = currency ? { code: currency } : undefined
      setCurrency(currency)
      persistLocale({ currency })
    },
    [defaultCurrency?.code, currencyRates.code]
  )
  const updateLocaleUnits = useCallback(
    units => {
      if (!units && defaultUnits) {
        units = defaultUnits
      }
      if (!units) {
        units = DEFAULT_LOCALE_UNITS
      }
      setUnits(units)
      persistLocale({ units })
    },
    [defaultUnits]
  )
  useEffect(() => {
    // undefined prefCurrency is initialization / default
    // null prefCurrency is clearing the preference
    if (prefCurrency || prefCurrency === null) {
      updateLocaleCurrency((prefCurrency && prefCurrency.code) || null)
    }
  }, [prefCurrency, updateLocaleCurrency])
  useEffect(() => {
    // undefined prefUnits is initialization / default
    // null prefUnits is clearing the preference
    if (prefUnits || prefUnits === null) {
      updateLocaleUnits(prefUnits)
    }
  }, [prefUnits, updateLocaleUnits])
  const setPreferredCurrency = currency => {
    // update values in locale context
    updateLocaleCurrency(currency)

    // persist to preferences layer
    updatePrefCurrency(currency)
  }
  const setPreferredUnits = units => {
    // update values in locale context
    updateLocaleUnits(units)

    // persist to preferences layer
    updatePrefUnits(units)
  }

  const convertCurrency = useCallback(
    (amount, fromCode, toCode) => {
      if (!fromCode && currency) {
        fromCode = currency.code
      }
      if (!toCode && currency) {
        toCode = currency.code
      }
      if (fromCode === toCode) {
        return amount
      }
      if (
        !currencyRates ||
        !currencyRates.exchange ||
        !currencyRates.exchange[fromCode] ||
        !currencyRates.exchange[toCode]
      ) {
        return null
      }

      const exchangeTax = 1.0 + CURRENCY_EXCHANGE_TAX / 100.0
      const exchangeTaxMulti =
        fromCode === currencyRates.code
          ? exchangeTax * exchangeTax // exchange from base does not already have exchangeTax applied
          : toCode !== currencyRates.code
          ? exchangeTax // not exchanging to or from base will cancel exchangeTax, ensure it is applied
          : 1.0 // exchanging to base already has exchangeTax applied by the API

      return (
        ((amount * currencyRates.exchange[fromCode]) /
          currencyRates.exchange[toCode]) *
        exchangeTaxMulti
      )
    },
    [currencyRates, currency]
  )
  return (
    <LocaleContext.Provider
      value={{
        country,
        currency,
        convertCurrency,
        units,
        setPreferredUnits,
        setPreferredCurrency,
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}
LocaleProvider.propTypes = {
  children: PropTypes.node,
}
