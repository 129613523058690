import axios from "axios"
import { graphqlEndpoint } from "../_config"
import { clientQueryGetUserLocale } from "./queries"
import Locale from "./index"
import GTM from "@src/services/gtm"
import UserPrefs from "../prefs"

export const detectLocale = ({ defaultCurrency } = {}) =>
  axios
    .post(
      graphqlEndpoint,
      JSON.stringify({
        query: clientQueryGetUserLocale(),
        variables: defaultCurrency
          ? {
              currency: defaultCurrency,
            }
          : undefined,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(response => {
      if (!response || !response.data?.data?.visitor?.locale) {
        return null
      }
      const localeData = response.data.data.visitor.locale

      // reshape/clean the result data
      const newLocale = localeData
        ? {
            country: localeData.country.toUpperCase(),
            currency: {
              code: undefined,
            },
            currencyRates: {
              code: undefined,
              exchange: {},
            },
            units: localeData.units.toLowerCase(),
          }
        : null

      // process currency
      if (localeData && localeData.currency && localeData.currency.code) {
        // currency code
        newLocale.currencyRates.code = localeData.currency.code.toUpperCase()
        newLocale.currency.code = newLocale.currencyRates.code

        // read in exchange rates
        if (
          localeData.currency.exchangeRates &&
          localeData.currency.exchangeRates.length
        ) {
          newLocale.currencyRates.exchange =
            localeData.currency.exchangeRates.reduce((result, exchange) => {
              if (
                exchange.fromCode &&
                exchange.toCode &&
                exchange.toCode.toUpperCase() === newLocale.currency.code
              ) {
                result[exchange.fromCode.toUpperCase()] = exchange.rate
              }
              return result
            }, {})
        }
      }
      newLocale.defaultCurrency = newLocale.currency
      newLocale.defaultUnits = newLocale.units

      const userPrefs = UserPrefs.getPrefs()
      if (userPrefs) {
        if (userPrefs.units) {
          newLocale.units = userPrefs.units
        }
        if (userPrefs.currency) {
          newLocale.currency = userPrefs.currency
        }
      }

      const dataLayer = {
        event: "10a.user.locale.detected",
        "user-locale-country": "",
        "user-locale-currency": "",
        "user-locale-units": "",
      }
      if (newLocale) {
        if (newLocale.country) {
          dataLayer["user-locale-country"] = newLocale.country
        }
        if (newLocale.currency && newLocale.currency.code) {
          dataLayer["user-locale-currency"] = newLocale.currency.code
        }
        if (newLocale.units) {
          dataLayer["user-locale-units"] = newLocale.units
        }
      }
      GTM.dataLayerPush(dataLayer)

      // preserve in localstorage
      Locale.setLocale(newLocale)

      return Locale.getLocale()
    })
    .catch(error => console.log("Error in detectLocale request: ", error))
