import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import Prefs from "@src/services/user/prefs"
import { UserContext } from "@src/context/user-context"

export const PrefsContext = React.createContext({
  prefUnits: null,
  updatePrefUnits: () => {},
  prefCurrency: null,
  updatePrefCurrency: () => {},
})

const getUserPreference = (user, key) => {
  if (!user || !key || !user.preferences || !user.preferences[key]) {
    return
  }
  return user.preferences[key]
}

export const PrefsProvider = ({ children }) => {
  const { user, updateUserPreferences } = useContext(UserContext)

  const persistPreferences = changes => Prefs.updatePrefs(changes)

  const [hasUser, setHasUser] = useState(false)
  const [prefUnits, setPrefUnits] = useState()
  const [prefCurrency, setPrefCurrency] = useState()
  useEffect(() => {
    const initPrefs = Prefs.getPrefs(true)
    if (initPrefs && initPrefs.units) {
      setPrefUnits(initPrefs.units)
    }
    if (initPrefs && initPrefs.currency) {
      setPrefCurrency(initPrefs.currency)
    }
  }, [])

  // handle changes to the user
  useEffect(() => {
    const prefChanges = {}
    if (user) {
      if (!hasUser) {
        setHasUser(true)
      }
      // load and set preferences from the user
      const userPrefUnits = getUserPreference(user, "units")
      const userPrefCurrency = getUserPreference(user, "currency")
      if (userPrefUnits) {
        setPrefUnits(userPrefUnits)
        prefChanges.units = userPrefUnits
      }
      if (userPrefCurrency) {
        setPrefCurrency({ code: userPrefCurrency })
        prefChanges.currency = { code: userPrefCurrency }
      }
    } else if (hasUser) {
      setHasUser(false)
      // reset the preferences
      setPrefUnits(null)
      setPrefCurrency(null)
      prefChanges.units = undefined
      prefChanges.currency = undefined
    }
    persistPreferences(prefChanges)
  }, [user, hasUser])

  const updatePrefUnits = units => {
    // update state
    setPrefUnits(units)

    // persist the Prefs localstorage
    persistPreferences({ units })

    // persist to user's profile
    updateUserPreferences({ units })
  }

  const updatePrefCurrency = currency => {
    const currencyValue = currency ? { code: currency } : undefined
    // update state
    setPrefCurrency(currencyValue)

    // persist the Prefs localstorage
    persistPreferences({ currency: currencyValue })

    // persist to user's profile
    updateUserPreferences({ currency })
  }
  const prefs = {
    prefUnits,
    updatePrefUnits,
    prefCurrency,
    updatePrefCurrency,
  }
  return <PrefsContext.Provider value={prefs}>{children}</PrefsContext.Provider>
}
PrefsProvider.propTypes = {
  children: PropTypes.node,
}
