// source: https://stackoverflow.com/a/56781239/1798697
export const invertObject = obj =>
  Object.fromEntries(Object.entries(obj).map(a => a.reverse()))

// source: https://stackoverflow.com/a/52323412/1798697
export const shallowCompare = (obj1, obj2) => {
  return (
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(key => obj1[key] === obj2[key])
  )
}

