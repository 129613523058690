import React from "react"

import { ThemeProvider } from "styled-components"

import { exists, window, CustomEvent } from "browser-monads"

import AffiliateReferrals from "./src/services/affiliates/referrals"

import { UserProvider } from "./src/context/user-context"
import { PrefsProvider } from "./src/context/prefs-context"
import { LocaleProvider } from "./src/context/locale-context"
import { LayerProvider } from "./src/context/layer-context"

import theme from "./src/styles/theme"

export const onInitialClientRender = () => {
  if (exists(window) && exists(CustomEvent)) {
    window.dispatchEvent(new CustomEvent("10a.locale.detect"))
  }
  AffiliateReferrals.initialize()
}
export const onRouteUpdate = ({ location, prevLocation }) => {
  AffiliateReferrals.checkReferralCodes({ location, prevLocation })
}

export const wrapRootElement = ({ element }) => {
  return (
    <UserProvider>
      <PrefsProvider>
        <LocaleProvider>
          <ThemeProvider theme={theme}>
            <LayerProvider>{element}</LayerProvider>
          </ThemeProvider>
        </LocaleProvider>
      </PrefsProvider>
    </UserProvider>
  )
}
